<template>
  <div class="text-left">
    <Breadcrumb
      :home="breadcrumbHome"
      :model="breadcrumbItems"
      class="p-mt-4 p-mb-4"
    />
    <div class="generate-qrcode">
      <div v-if="step === 1" class="flex flex-col align-center">
        <div class="card" v-if="documents.length > 1">
          <Carousel :value="documents" :numVisible="3" :numScroll="1">
            <template #header>
              <h5>{{ i18n.$t('Choose a document') }}</h5>
            </template>
            <template #item="slotProps">
              <div class="product-item">
                <div class="product-item-content">
                  <div>
                    <h4 class="p-mb-1">{{ slotProps.data.name }}</h4>
                    <h6 class="p-mt-0 p-mb-3"></h6>
                    <div class="car-buttons p-mt-5">
                      <Button
                        icon="pi pi-file-o"
                        class="p-button-secondary"
                        @click="generateQRCode(slotProps.data.url)"
                      />
                    </div>
                    <div class="p-mb-3">
                      <h6 class="p-mb-1">{{ slotProps.data.description }}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Carousel>
        </div>
        <div v-if="documents.length == 1">
          <h5>{{ i18n.$t('Choose a document') }}</h5>
          <div class="product-item">
            <div class="product-item-content">
              <div>
                <h4 class="p-mb-1">{{ documents[0].name }}</h4>
                <h6 class="p-mt-0 p-mb-3"></h6>
                <div class="car-buttons p-mt-5">
                  <Button
                    icon="pi pi-file-o"
                    class="p-button-secondary"
                    @click="generateQRCode(documents[0].url)"
                  />
                </div>
                <div class="p-mb-3">
                  <h6 class="p-mb-1">{{ documents[0].description }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="documents.length == 0">
          <span>{{ i18n.$t('No documents') }}</span>
          <div class="p-mt-3">
            <router-link to="/document/add">
              <button class="p-button">
                {{ i18n.$t('Create documents') }}
              </button>
            </router-link>
          </div>
        </div>
      </div>
      <div v-if="step === 2" class="step-form flex flex-col align-center">
        <div class="flex justify-center mt-4">
          <vue-qrcode :value="link" />
        </div>
        <p v-if="isError" class="text-red-500 mt-4 mb-4">
          {{ i18n.$t('Oops, something went wrong!') }}
        </p>
        <div class="flex flex-row justify-center">
          <Button
            @click="step = 1"
            type="submit"
            class="loading-btn font-semibold p-mt-4"
          >
            <spinner v-if="isLoading" color="white" />
            <span v-if="!isLoading">{{ i18n.$t('Cancel') }}</span>
          </Button>

          <Button
            @click="onSave"
            type="submit"
            class="loading-btn font-semibold p-mt-4"
          >
            <spinner v-if="isLoading" color="white" />
            <span v-if="!isLoading">{{ i18n.$t('Save') }}</span>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import axios from '@/plugins/axios';
import Spinner from '../../components/UI/Spinner.vue';
import VueQrcode from 'vue3-qrcode';
import {
  FETCH_DOCUMENTS,
  GET_DOCUMENTS,
  GET_USERDATA
} from '../../store/types';
import useI18n from '@/plugins/useI18n';
export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/' },
    breadcrumbItems: [{ label: 'QR code list', to: '/qrcode' }]
  }),
  components: {
    VueQrcode,
    Spinner
  },
  setup() {
    const { i18n } = useI18n();
    const store = useStore();
    const router = useRouter();
    const documents = computed(() => store.getters[GET_DOCUMENTS]);
    const userData = computed(() => store.getters[GET_USERDATA]);
    const step = ref(1);
    const link = ref('');
    const owner_id = userData.value.id;
    store.dispatch(FETCH_DOCUMENTS, owner_id);
    const isLoading = ref(false);
    const showResult = ref(false);
    const isError = ref(false);
    function generateQRCode(url) {
      if (url !== '') {
        step.value = 2;
        link.value = url;
      }
    }
    if (documents) {
      const document = documents.value.map((i, k) => {
        return i.fichier;
      });
    }

    async function onSave() {
      isLoading.value = true;
      try {
        await axios.post('/qrcode', {
          owner_id,
          link: link.value,
          qrcode: link.value
        });
        isError.value = false;
      } catch {
        isError.value = true;
      }

      isLoading.value = false;
      if (!isError.value) {
        router.push({ path: '/qrcode/list' });
      }
    }

    return {
      i18n,
      step,
      documents,
      link,
      generateQRCode,
      onSave,
      isLoading,
      showResult,
      isError
    };
  }
};
</script>

<style lang="scss" scoped>
.step-form {
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
}
.loading-btn {
  width: 80px;
  height: 40px;
}

.product-item {
  .product-item-content {
    border: 1px solid var(--surface-d);
    border-radius: 3px;
    margin: 0.3rem;
    text-align: center;
    padding: 2rem 0;
  }

  .product-image {
    width: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}
</style>
