<template>
  <div class="text-left">
    <Breadcrumb
      :home="breadcrumbHome"
      :model="breadcrumbItems"
      class="p-mt-4 p-mb-4"
    />
    <div
      v-if="loading"
      class="p-justify-center p-d-flex p-content-center p-my-5"
    >
      <ProgressSpinner
        style="width: 50px; height: 50px"
        strokeWidth="8"
        fill="#EEEEEE"
        animationDuration=".5s"
      />
    </div>
    <DataTable
      v-if="!loading"
      :value="data"
      dataKey="_id"
      :rowHover="true"
      class="apin-data-table p-mt-2"
      v-model:selection="selected"
    >
      <template #header>
        <div class="table-header">List of Notifications</div>
      </template>
      <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
      <Column field="contact" header="Contact"> </Column>
      <Column field="_status" header="Contact counts"> </Column>
    </DataTable>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import {
  FETCH_INFORMATION_NOTIFICATIONS,
  GET_INFORMATION_NOTIFICATIONS
} from '../../store/types';
import useI18n from '@/plugins/useI18n';
export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/' },
    breadcrumbItems: [{ label: 'Information list', to: '/information/list' }]
  }),
  setup() {
    const { i18n } = useI18n();
    const route = useRoute();
    const store = useStore();
    const data = computed(() => store.getters[GET_INFORMATION_NOTIFICATIONS]);
    console.log(data);
    const selected = ref([]);
    const loading = ref(true);
    const informationID = route.query.information;
    async function load() {
      await store.dispatch(FETCH_INFORMATION_NOTIFICATIONS, informationID);
      loading.value = false;
    }
    load();

    return { i18n, data, selected, loading };
  }
};
</script>
