import {UserDataInfo} from '@/plugins/api'

const userData = new UserDataInfo();
export default class InfoModel {
  constructor(item) {
    this.owner_id = userData.ownerId();
    this.organisation_id = userData.organisationId();
    this._action = item._action ? item._action : null;
    this.document_id = item.document_id ? item.document_id : [],
    this._status = item._status ? item._status : 'New',
    this.name = item.name ? item.name : null,
    this.description = item.description ? item.description : null,
    this.events = item.events ? item.events : [],
    this.groups = item.groups ? item.groups : [],
    this.files = item.files ? item.files : [];
  }
}
