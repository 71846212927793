<template>
  <div class="actions p-grid p-nogutter p-justify-between p-mt-4">
    <Button
      label="Back"
      icon="pi pi-angle-left"
      @click="prevStep(), loadStep()"
      :disabled="step == 1"
    />
    <div>
      <Button
        label="Next"
        icon="pi pi-angle-right"
        iconPos="right"
        :disabled="step == stepItems.length || !allowed"
        v-if="step !== stepItems.length"
        @click="nextStep(), loadStep()"
      />
    </div>
    <Button
      label="Submit"
      icon="pi pi-check"
      iconPos="right"
      class="p-button-success"
      :disabled="!allowed"
      v-if="step == stepItems.length"
      @click="$emit('complete')"
    >
      <Spinner v-if="isLoading" color="white" />
      {{ !isLoading ? i18n.$t('Save') : null }}
    </Button>
  </div>
</template>

<script>
import useI18n from '@/plugins/useI18n';
import { useRouter } from 'vue-router';
import { ref, computed } from 'vue';
import Spinner from '@/components/UI/Spinner'
export default {
  props: {
    stepItems: {
      type: Object
    },
    currentStep: {
      type: Number
    },
    completeButtonLoading: {
      default: false,
    },
    requiredStepByStep: {
      type: Array,
    },
    items: {
      type: Object
    },
    isLoading: {
      type: Boolean,
    },
    eventSaveClick:{
      type: Boolean
    }
  },
  components: {
    Spinner
  },
  setup(props, { emit }) {
    const router = useRouter();
    const step = ref(1);
    const { i18n } = useI18n();

    const allowed = computed(() => {
      let value = true;
      if(props.requiredStepByStep && props.requiredStepByStep[step.value-1]) {
        let required = props.requiredStepByStep[step.value-1];
        required.forEach(key => {
          if(Array.isArray(props.items[key]) && props.items[key].length == 0 || !props.items[key]) {
            value = false;
          }
        })
      }
      return value;
    })
    
    function nextStep() {
      step.value !== props.stepItems.length ? step.value++ : step.value.length;
    }

    function prevStep() {
      step.value !== 1 ? step.value-- : 1;
    }

    function loadStep() {
      const elem = props.stepItems[step.value - 1];
      const stepName = elem.name;
      const query = elem.query;
      router.push({ name: stepName, query:  query});

      emit('updateStep', step.value);
    }

    return {
      loadStep,
      nextStep,
      prevStep,
      allowed,
      i18n,
      step
    };
  }
};
</script>

<style lang="scss" scoped>
.actions {
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
  .p-button {
    width: auto;
  }
}
</style>