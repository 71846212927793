<template>
  <TopBar :breadcrumbItems="breadcrumbItems" />

  <div class="card">
    <Steps :model="stepItems" :readonly="true" />
  </div>

  <h3 class="font-semibold p-mt-4">{{ stepItems[currentStep - 1].label }}</h3>
  <router-view
    class="information-steps"
    :item="information"
    @event-saved="onSaveEvent"
  >
  </router-view>

  <StepPagination
    :currentStep="currentStep"
    :stepItems="stepItems"
    :isLoading="isLoading"
    :requiredStepByStep="requiredStepByStep"
    :items="information"
    :eventSaveClick="eventSaveClick"
    v-if="information"
    @updateStep="currentStep = $event"
    @complete="onSave"
  />
  <Button
    @click="$router.push({ path: '/information/list' })"
    label="Plain"
    class="p-button p-mt-4"
  >
    <span>{{ i18n.$t('Cancel') }}</span>
  </Button>
  <div class="form-input p-mt-2">
    <transition-group name="p-messages" tag="div">
      <Message
        v-for="msg of message"
        :severity="msg.severity"
        :key="msg.content"
      >
        {{ msg.content }}
      </Message>
    </transition-group>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from '@/plugins/axios';
import Spinner from '@/components/UI/Spinner.vue';
import TopBar from '@/components/Utilities/TopBarBreadCrumb.vue';
import StepPagination from '@/components/Utilities/StepPagination.vue';
import useI18n from '@/plugins/useI18n';
import InfoModel from '@/models/information';
export default {
  components: {
    Spinner,
    TopBar,
    StepPagination
  },
  data: () => ({
    breadcrumbItems: [{ label: 'Information list', to: '/information/list' }]
  }),
  setup() {
    const router = useRouter();
    const isLoading = ref(false);
    const message = ref([]);
    const { i18n } = useI18n();
    const eventSaveClick = ref(false);
    const route = useRoute();

    const requiredStepByStep = reactive([
      ['name', 'description', 'document_id'],
      ['_action'] //event
    ]);

    const stepItems = [
      {
        label: `${i18n.$t('Edit information')}`,
        name: 'InformationEditStep1',
        to: '/information/edit/step1',
        query: { information: route.query.information }
      },
      {
        label: `${i18n.$t('Edit')} ${i18n.$t('Action')}`,
        name: 'InformationEditStep2',
        to: '/information/edit/step2',
        query: { information: route.query.information }
      },
      {
        label: `${i18n.$t('Edit')} ${i18n.$t('Groups')}`,
        name: 'InformationEditStep3',
        to: '/information/edit/step3',
        query: { information: route.query.information }
      },
      {
        label: `${i18n.$t('Information')} ${i18n.$t('Details')}`,
        name: 'InformationEditStep4',
        to: '/information/edit/step4',
        query: { information: route.query.information }
      }
    ];

    const information = reactive(new InfoModel({}));

    axios.get(`information/${route.query.information}`).then((response) => {
      const data = response.data.data.items[0];
      //console.log('info ', data);
      for (const key in data) {
        information[key] = data[key];
      }
      information.events = [];
      information.groups = [];
      information.document_id = data.document.id;
      for (const item of data.events) {
        information.events.push(item._id);
      }
      for (const item of data.groups) {
        information.groups.push(item._id);
      }
    });

    onMounted(() => {
      router.push({
        name: 'InformationEditStep1',
        query: { information: route.query.information }
      });
    });

    const onSave = async () => {
      isLoading.value = true;
      message.value = [];
      //console.log(information);return
      try {
        await axios.put('information/' + information._id, {
          owner_id: information.owner_id,
          _action: information._action,
          document_id: information.document_id,
          name: information.name,
          description: information.description,
          organisation_id: information.organisation_id,
          files: information.files,
          groups_id: information.groups,
          events_id: information.events
        });
        showMessage(true);
        setTimeout(() => {
          router.push({ path: '/information/list' });
        }, 1000);
      } catch (error) {
        showMessage(false);
        console.log(error);
      }
      isLoading.value = false;
    };

    function showMessage(type) {
      let value;
      if (type) {
        value = { severity: 'success', content: 'Saved successfully' };
      } else
        value = { severity: 'error', content: 'Oops, something went wrong!' };
      message.value = [value];
    }

    const currentStep = ref(1);

    const onSaveEvent = () => {
      eventSaveClick.value = true;
    };

    return {
      isLoading,
      message,
      onSave,
      currentStep,
      information,
      requiredStepByStep,
      i18n,
      stepItems,
      onSaveEvent,
      eventSaveClick
    };
  }
};
</script>
