import { UserDataInfo } from '@/plugins/api'

const userData = new UserDataInfo();
export default class InformationViews {
  constructor(item) {
    if (item) {
      this.owner_id = userData.ownerId();
      this.organisation_id = userData.organisationId();
      this._id = item._id ? item._id : null;
      this._action = item._action ? item._action : null;
      this._status = item._status ? item._status : null;
      this.name = item.name ? item.name : null;
      this.description = item.descriptionpe ? item.description : null;
      this.document = item.document ? item.document : {
        id: null,
        name: null,
        type: null
      }
    }
  }
}
