<template>
  <Card>
    <template #content>
      <div class="col-6">
        <div class="doc-tablewrapper">
          <table class="doc-table">
            <tr>
              <td>{{ i18n.$t('Name') }}:&nbsp;</td>
              <td>{{ information.name }}</td>
            </tr>
            <tr>
              <td>{{ i18n.$t('Status') }}:&nbsp;</td>
              <td>
                {{ information._status }}
              </td>
            </tr>
            <tr>
              <td>{{ i18n.$t('Action') }}:&nbsp;</td>
              <td>
                {{ information._action }}
              </td>
            </tr>
            <tr>
              <td>{{ i18n.$t('Events') }}:</td>
              <td>
                <div
                  class="p-py-2"
                  v-for="event in information.events"
                  :key="event"
                  style="border-bottom: 1px solid #bbb"
                >
                  name : <b>{{ event.name }}</b>
                  <br />
                  date : <b>{{ formatDate(event.date) }}</b>
                  <br />
                  type : <b>{{ event._type }}</b>
                  <br />
                  description : <div class="p-m-2" v-html="event.description"></div>
                </div>
              </td>
            </tr>
            <tr>
              <td>{{ i18n.$t('Groups') }}:</td>
              <td
                class="p-mr-3"
                v-for="group in information.groups"
                :key="group"
              >
                {{ group.name + ' (' + group.count + ') ' }}
              </td>
            </tr>
            <tr>
              <td>{{ i18n.$t('Description') }}:&nbsp;</td>
              <td>
                <div v-html="information.description"></div>
              </td>
            </tr>
            <tr>
              <td>{{ i18n.$t('Document') }}:</td>
              <td style="display: block">
                <router-link
                  :to="{
                    path: '/document/view',
                    query: { document: information.document.id }
                  }"
                >
                  {{
                    information.document.name +
                    ' (' +
                    information.document.type +
                    ')'
                  }}
                </router-link>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import { useRoute } from 'vue-router';
import { reactive } from 'vue';
import axios from '@/plugins/axios';
import useI18n from '@/plugins/useI18n';
import InformationViews from '@/models/information-views';
import moment from 'moment';
export default {
  props: {
    type: {
      type: String,
      default: 'view'
    }
  },
  setup() {
    const route = useRoute();
    const { i18n } = useI18n();

    const information = reactive(new InformationViews({}));
    axios.get(`information/${route.query.information}`).then((response) => {
      const data = response.data.data.items[0];
      for (const key in data) {
        information[key] = data[key];
      }
    });

    function formatDate(date) {
      return moment(date).format(i18n.$t('date_format'));
    }

    return {
      information,
      i18n,
      formatDate
    };
  }
};
</script>


<style lang="scss" scoped>
.form-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
  &.file-upload {
    flex-direction: row !important;
    align-items: center;
  }
}
.loading-btn {
  width: 100%;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.radio-input label {
  margin-left: 10px;
}
.selected {
  margin-bottom: 0.5rem;
  .p-col {
    padding: 0.25rem 0.5rem;
    &:first-of-type {
      padding: 0.5rem 0.5rem 0.25rem;
    }
  }
}
.tablewrapper {
  margin: 1rem 0;
  overflow: auto;
}
.doc-table {
  border-collapse: collapse;
  width: 100%;
  background-color: var(--surface-a);
}
.doc-table td {
  border-bottom: 1px solid var(--surface-d);
  padding: 1rem;
}
.doc-table td:first-child {
  font-family: Courier New, monospace;
  font-weight: 700;
  color: var(--text-color-secondary);
}
</style>
