<template>
  <Breadcrumb
    :home="breadcrumbHome"
    :model="breadcrumbItems"
    class="p-mt-4 p-mb-4"
  />
  <div class="send-information">
    <h3 class="font-semibold">{{ i18n.$t('Send Notification') }}</h3>

    <div>
      <div class="information-content">
        <Information />
      </div>
      <div class="p-d-flex p-mt-4">
        <Button
          @click="$router.push({ path: '/information/list' })"
          label="Plain"
          class="p-button p-mr-3"
        >
          <span>{{ i18n.$t('Cancel') }}</span>
        </Button>
        <Button
          :disabled="!canSendNotif()"
          id="send-notif"
          class="font-semibold loading-btn"
          @click="sendConfirm"
          v-if="!send"
        >
          <spinner v-if="isLoading" color="white" />
          <span v-if="!isLoading">{{
            i18n.$t('Confirm Send Notification')
          }}</span>
        </Button>
        <router-link
          :to="{
            path: '/information/view',
            query: { information: $route.query.information }
          }"
          v-else
        >
          <Button id="send-notif" class="font-semibold loading-btn">
            <span>{{ i18n.$t('See details') }}</span>
          </Button>
        </router-link>
      </div>

      <div class="p-mt-2">
        <transition-group name="p-messages" tag="div">
          <Message
            v-for="msg of message"
            :severity="msg.severity"
            :key="msg.content"
          >
            {{ msg.content }}
          </Message>
        </transition-group>
      </div>
    </div>
  </div>
  <ConfirmPopup></ConfirmPopup>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import axios from '@/plugins/axios';
import Spinner from '../../components/UI/Spinner.vue';
import useI18n from '@/plugins/useI18n';
import Information from '@/components/Information/InformationView.vue';
import ConfirmPopup from 'primevue/confirmpopup';
import { useConfirm } from 'primevue/useconfirm';
import { usePrimeVue } from 'primevue/config';

import {
  GET_INFORMATION_2_NOTIFICATION,
  FETCH_GROUPS,
  GET_GROUPS,
  GET_USERDATA
} from '../../store/types';
import { useRouter } from 'vue-router';
export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/' },
    breadcrumbItems: [{ label: 'Information list', to: '/information/list' }]
  }),
  components: {
    Spinner,
    Information,
    ConfirmPopup
  },
  setup() {
    const { i18n } = useI18n();
    const store = useStore();
    const router = useRouter();
    const information = computed(
      () => store.getters[GET_INFORMATION_2_NOTIFICATION]
    );
    const selectGroupPage = ref(true);
    const groups = computed(() => store.getters[GET_GROUPS]);
    const userData = computed(() => store.getters[GET_USERDATA]);

    const selected = ref([]);
    const isLoading = ref(false);
    const message = ref([]);
    const primevue = usePrimeVue();
    const send = ref(false);

    primevue.config.locale.accept = i18n.$t('Yes');
    primevue.config.locale.reject = i18n.$t('No');

    const confirm = useConfirm();

    store.dispatch(FETCH_GROUPS, userData.value.id);

    function sendConfirm(event) {
      confirm.require({
        target: event.currentTarget,
        message: i18n.$t('Are you sure you want to proceed?'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          onSend();
        }
      });
    }
    async function onSend() {
      isLoading.value = true;
      message.value = [];
      try {
        //const __participants_id = selected.value.map((grp) => grp._id);
        let __participants_id = [];
        if (information.value.groups) {
          information.value.groups.forEach((gr) => {
            __participants_id.push(gr._id);
          });
        }
        await axios.post('notification', {
          _status: 'New',
          _type: 'Information',
          owner_id: userData.value.id,
          __participants_id, // here must be contact list ids
          item_id: information.value._id
        });
        await axios.put(`information/${information.value._id}`, {
          _status: 'Sent'
        });
        send.value = true;
        showMessage(true);
      } catch {
        showMessage(false);
      }
    }
    function showMessage(type) {
      isLoading.value = false;
      if (type) {
        message.value = [
          { severity: 'success', content: i18n.$t('Saved successfully') }
        ];
      } else {
        message.value = [
          { severity: 'error', content: i18n.$t('Oops, something went wrong!') }
        ];
      }
    }
    function canSendNotif() {
      if (send.value) {
        return false;
      }
      let group_ids = [];
      if (information.value.groups) {
        information.value.groups.forEach((gr) => {
          group_ids.push(gr._id);
        });
      }
      let can = false;
      if (groups.value.length > 0) {
        groups.value.forEach((gr) => {
          if (group_ids.includes(gr._id)) {
            if (gr.__contacts_id && gr.__contacts_id.length > 0) {
              can = true;
              return true;
            }
          }
        });
      }
      return can;
    }

    return {
      i18n,
      selectGroupPage,
      information,
      groups,
      selected,
      isLoading,
      message,
      sendConfirm,
      send,
      canSendNotif
    };
  }
};
</script>

<style lang="scss" scoped>
.send-information {
  width: 420px;
}
table {
  width: 100%;
}
td {
  padding: 5px;
}
.loading-btn {
  height: 40px;
  width: 160px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
